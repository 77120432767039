/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 09-sep-2017, 8:55:48
    Author     : carlos
*/



/* **** MNRO VARIABLES ***/

// layout
$content-padding-top: 110px;
$content-mobile-padding-top: 60px;

$separator-big-margin-top: 140px;
$separator-mobile-big-margin-top: 70px;

$content-margin-top: 140px;
$footer-height: 104px;


// breaks
$mnro-mobile-break: 479px;
$mnro-tablet-break: 767px;
$mnro-pc-break: 991px;
$mnro-large-pc-break: 1779px;


// palette
$header-text-color: #555;
$normal-text-color: #666;

$mnro-default-bg-color: #FFFFFF;
$mnro-bg-1-color: #FAFAFA;

$mnro-header-bg-color: #29394E;

$mnro-text-over-main-dark-color: #FFFFFF;
$mnro-main-dark-color: #29394E;




/* *** MNRO PAGE LAYOUT * ***/
* {
    margin: 0;
}

html, body {
    height: 100%;
}


.lt-ie10 .mnro-main-wrapper {

    display: none;

}


.mnro-main-wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto ($footer-height * -1); /* the bottom margin is the negative value of the footer's height */

    //display: none;

    @media( min-width: $mnro-large-pc-break ) {

        max-width: 1800px;

    }
}


.mnro-push {

    height: $footer-height * 2;
}



.mrno-outdated-browser-wrapper {

    width: 100%;

    display: none;

    .mnro-outdated-browser-disclaimer {

        vertical-align: baseline;

        padding: 10px 20px;

        background-color: #0A1B57;


        img {

            margin-right: 10px;

            float: left;

            height: 20px;

        }

        > div {

            line-height: 20px;

            font-size: 12px;

            display: inline-block;

            color: #FFFFFF;

            a {

                text-decoration: underline;

                font-size: 0.9em;

                color: #A6DEFD;

            }
        }


    }


    .mnro-outdated-browser-header {

        position: relative;

        img {

            width: 100%;
        }

        .mnro-credits {

            right: 10px;

            position: absolute;

            bottom: 0px;
        }

    }

    .mnro-outdated-browser-content {


        padding: 0 20px;

        margin-top: 40px;



        .mnro-o-b-title {

            width: 100%;

            text-align: center;

            margin-bottom: 40px;

            display: inline-block;

        }


        .mnro-o-b-main-content {

            width: 70%;

            float: left;

            p {

                padding-left: 34px;

                margin-bottom: 4px;
            }


            ul {

                margin: 0px 0px 4px 20px;

                li {

                    h4 {

                        margin-bottom: 0px;

                        font-weight: bold;

                        font-size: 10px;
                    }

                }
            }

            .mnro-o-b-primary-block {

                margin-bottom: 80px;


                h2 {

                    padding-left: 34px;

                    line-height: 26px;

                    font-size: 22px;

                    color: #0E2A5A;

                    background-repeat: no-repeat;


                }




            }


            .mnro-o-b-secondary-block {

                padding-left: 20px;

                margin-top: 20px;

                font-size: 0.90em;

                h3 {


                    padding-left: 34px;

                    letter-spacing: 2px;

                    margin-top: 24px;

                    font-weight: bold;

                    font-size: 12px;

                    color: #70a9cd;

                }

            }


            .mnro-o-b-primary-block.mnro-o-b-servicios-it {

                h2 {

                    background-image: url('#{$imagesPath}/icons/servicios-it-icon-26h.png');

                }

            }


            .mnro-o-b-primary-block.mnro-o-b-desarrollo-sw {

                h2 {

                    background-image: url('#{$imagesPath}/icons/desarrollo-web-icon-26h.png');

                }

            }               


            .mnro-o-b-primary-block.mnro-o-b-prototipado {

                h2 {

                    background-image: url('#{$imagesPath}/icons/prototipado-icon-26h.png');

                }

            }

        }


        .mnro-o-b-aside-content {

            width: 28%;

            padding: 0 0 0 20px;

            margin-bottom: 60px;

            font-size: 0.9em;

            float: right;

            border-left: 1px solid #6A7AA7;


            .mnro-o-b-aside-block {

                margin-bottom: 20px;

                position: relative;

                padding: 10px 10px 10px 60px;

                border-radius: 4px;

                border: 1px solid #e2e1e1;

                background-color: #FAFAFA;


                h3 {

                    margin-bottom: 4px;

                    font-size: 12px;

                    color: #0E2A5A;
                }

                p {

                    margin: 0;

                    font-size: 0.9em;

                }



                .mnro-o-b-aside-icon {

                    position: absolute;

                    margin-top: -10px;

                    top: 50%;

                    left: 20px;

                }


            }

        }


        .mnro-o-b-contact-wrapper {


            text-align: center;

            .mnro-o-b-contact {

                width: 40%;

                padding: 10px 20px;

                min-width: 480px;

                margin-bottom: 40px;

                display: inline-block;

                border-radius: 4px;

                border: 1px solid #e2e1e1;

                .mnro-o-b-contact-block {

                    width: 48%;

                    text-align: center;


                    h4 {


                        line-height: 20px;

                        font-size: 14px;

                        i {

                            font-size: 1.5rem;
                            position: relative;
                            top: 4px;
                            margin-right: 8px;

                        }

                    }


                }


                .mnro-o-b-contact-block.mnro-o-b-email-contact-block {

                    float: left;


                    h4 {

                        color: #518dc3;
                    }
                }


                .mnro-o-b-contact-block.mnro-o-b-phone-contact-block {

                    float: right;


                    h4 {

                        color: #4fc4c5;
                    }
                }


            }


        }


        .mnro-o-b-footer {

            text-align: center;

            margin-bottom: 20px;

            font-size: 0.9em;

            > span {

                margin-right: 40px;
            }

        }

        .mnro-o-b-resource-download-wrapper {

            text-align: center;

            margin-bottom: 60px;

            .mnro-o-b-resource-download {

                width: 80%;

                padding: 10px 20px;

                min-width: 480px;

                margin-bottom: 40px;

                font-weight: bold;

                font-size: 1.3em;

                display: inline-block;

                border-radius: 4px;

                border: 1px solid #e2e1e1;

                background-color: #FAFAFA;

            }

        }

    }


}



.lt-ie10 .mrno-outdated-browser-wrapper {

    display: inline-block !important;

}



/* **** MNRO ELEMENTS **** */
.mnro-invisible {

    visibility: hidden;
}


.mnro-top-separed {

    border-top: 1px solid #e2e1e1;

}


.mnro-bottom-separed {


    border-bottom: 1px solid #e2e1e1;

}



.mnro-go-home-link {


    cursor: pointer;


}

/* **** MNRO LAYOUT **** */
.mnro-main-block {

    padding-top: $content-padding-top;

    @media( max-width: $mnro-tablet-break ) {

        padding-top: $content-mobile-padding-top;

    }


}


.mnro-secondary-block {

    padding-top: $content-padding-top / 2;

    @media(max-width: $mnro-tablet-break) {
        padding-top: $content-mobile-padding-top / 2;
    }

}



.mnro-big-separator {

    padding-top: $separator-big-margin-top;

    @media(max-width: $mnro-tablet-break ) {
        padding-top: $separator-mobile-big-margin-top;
    }

}



.mnro-normal-separator {

    padding-top: $separator-big-margin-top / 2;

    @media(max-width: $mnro-tablet-break ) {
        padding-top: $separator-mobile-big-margin-top / 2;
    }

}


.mnro-bg-1 {

    background-color: $mnro-bg-1-color;

}



.mnro-top-navbar {


    @media( min-width: $mnro-large-pc-break ) {

        max-width: 1800px;

    }

    .container {

        @media(min-width: 0px) {

            max-width: 95%;

        }

    }




}

/* *** MNRO GRID *** */
.hidden-sm-down {

    @media (max-width: $mnro-mobile-break) {

        display: none !important;
    }

}


/* *** MNRO TOP NAV BAR *** */
.mnro-top-navbar .container {






}




/* *** MNRO FORM *** */
.mnro-form {

    form {
        width: 550px;
        margin: 35px auto 0;

        @media(max-width: $mnro-tablet-break) {
            width: 100%;
        }

        input[type="text"],
        input[type="email"],
        textarea {
            font-size: 18px;
            margin-top: 20px;
            outline: none;
            color: #888;
            background: white;
            padding: 18px 20px;
            border: 1px solid #cbcdd2;
            width: 100%;
            border-radius: 4px;

            @include transition(all .2s);

            @include placeholder {
                color: #B8BBC7;
            }

            @media(max-width: $mnro-tablet-break) {
                padding: 15px 20px;
                font-size: 16px;
            }

            &:focus {
                border-color: #656565;

                @include placeholder {
                    color: #666;
                }
            }
        }

        input[type="button"], input[type="submit"] {
            background: linear-gradient(to bottom, #6fb1f5 0%, #3b97f7 100%);
            box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px;
            width: 100%;
            padding: 18px 20px;
            text-align: center;
            outline: none;
            border: none;
            cursor: pointer;
            margin-top: 23px;
            font-weight: 500;
            border-radius: 4px;
            font-size: 18px;
            color: #fff;
            text-shadow: 1px 1px 0px rgba(106, 132, 158, 0.6);

            @include transition(all .2s);

            &:hover {
                color: #fff;
            }
        }



        .form-error.help-block {

            display: none;

        }


        .error-msg {

            padding-left: 0.4em;

            font-style: italic;

            font-size: 0.9em;

            color: #b94a48;

        }



    }
}



.mnro-form-intro {

    margin-top: 50px;

    p {
        font-size: 18px;
        line-height: 32px;
        font-weight: 300;
        text-align: center;
        width: 75%;
        margin: 27px auto 0;
        color: #61686E;

        @media(max-width: $mnro-tablet-break) {
            width: auto;
            font-size: 15px;
            line-height: 27px;
        }
    }

    p.disclaimer {

        line-height: 24px;
        font-size: 14px;
    }
}


/* *** MNRO HEADER *** */
.mnro-header {

    background-size: cover;
    background-position: center 0px;

    display: flex;


    //padding-top: 270px;
    overflow: hidden;
    position: relative;
    max-height: 600px;

    height: 70vh;

    align-items: center;

    .mnro-container {


        width: 100%;

        text-align: center;

        padding-top: 40px;

        height: fit-content;

        @media(max-width: $mnro-tablet-break) {

            padding: 0 1em;
        }

        h4, h5, h6 {

            width: auto;

            text-shadow: 0px 0px 20px #0B1016;

            margin: 0 auto;

            padding: 0;

            display: inline-block;

            color: #FFFFFF;

            @media(max-width: $mnro-mobile-break) {

                padding: 0 1em;
            }

        }

        h4 {

            font-size: 42px;

            @media(max-width: $mnro-mobile-break) {

                font-size: 24px;
            }


        }

        h5 {

            font-size: 36px;

            @media(max-width: $mnro-mobile-break) {

                font-size: 24px;
            }

        }

        h6 {

            font-size: 20px;

            @media(max-width: $mnro-mobile-break) {

                font-size: 14px;
            }

        }

        .separator {

            height: 0.2em;
        }

    }

    .mnro-credits {

        right: 10px;

        position: absolute;

        bottom: 0px;
    }

}


.mnro-full-header {

    @extend .mnro-header;

    position: relative;

    overflow: hidden;

    max-height: 100%;

    height: 100vh;

    background-image: none;

    background-color: $mnro-header-bg-color; 


    .brand-box {

        position: absolute;

        top: 20px;

        left: 0;

        right: 0;

        animation: blink 4s linear infinite;

    }

}

/* **** MNRO SECTIONS **** */
.mnro-main-features {

    .header {
        text-align: center;

        h1 {
            font-size: 32px;
            letter-spacing: .1px;
            font-weight: 400;
            color: #313b4e;

            @media(max-width: $mnro-tablet-break) {
                font-size: 23px;
            }
        }

        p {
            color: #5A6C90;
            margin-top: 15px;
            font-size: 16px;

            @media(max-width: $mnro-tablet-break) {
                font-size: 15px;
            }
        }
    }

    .feature {
        margin-top: 50px;

        @media( max-width: $mnro-pc-break ) {
            text-align: center;
        }

        h3 {
            color: #2F4166;
            font-size: 18px;
            margin-top: 20px;

            .badge-new {
                background-image: linear-gradient(-90deg, #609CFF 0%, #8D7FFF 100%);
                text-transform: uppercase;
                font-size: 12px;
                color: #fff;
                border-radius: 4px;
                padding: 3px 8px;
                letter-spacing: 0.5px;
                font-weight: bold;
                position: relative;
                top: -3px;
                left: 7px;
            }



        }

        p {
            color: #7080A2;
            line-height: 25px;
            font-size: 15px;
            margin-top: 15px;
            width: 87%;

            @media(max-width: $mnro-pc-break ) {
                width: auto;
            }


            .link-box {

                width: 100%;
                margin-top: 0.6em;
                margin-left: 0.3em;
                display: inline-block;

                a {


                    font-size: 0.9em;

                }

            }

        }
    }

}




.mnro-sub-features {

    background: #fafafa;

    header {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -50px;
            height: 1px;
            width: 87%;
            left: 0;
            right: 0;
            margin: auto;
            background: #eaeaea;
        }

        h3 {
            text-align: center;
            font-size: 26px;
            font-weight: 400;
            color: $header-text-color;

            @media(max-width: $mnro-tablet-break ) {
                font-size: 23px;
            }
        }

        p {
            color: $normal-text-color;
            font-size: 15px;
            text-align: center;
            line-height: 25px;
            width: 75%;
            margin: 0 auto;
            margin-top: 30px;


        }
    }

    .features {
        margin: auto;
        margin-top: 110px;
        width: 88%;

        @media(max-width: $mnro-tablet-break ) {
            width: 88%;
        }

        .feature {

            // IE10
            width: 100%;

            margin-bottom: 80px;


            @media(max-width: $mnro-pc-break ) {
                width: 100%;
                text-align: center;
            }

            .icon {
                @media(min-width: $mnro-pc-break ) {
                    margin-right: 20px;
                }
            }
        }

        .feature.last-child {

            margin-bottom: 0;
        }

        section {

            width: 83%;
            float: right;

            @media(max-width: $mnro-pc-break ) {
                width: 100%;
                margin-top: 20px;
                float: none;
            }

            h4 {
                margin-top: 0;
                font-size: 17px;
                color: #53575f;
            }

            p {
                margin-top: 18px;
                font-size: 15px;
                line-height: 24px;
                color: #666;
                width: 95%;
            }
        }
    }


}

.mnro-sub-features-1-col {

    .features {

        section {

            @media(min-width: $mnro-pc-break ) {

                width: 90%;
            }


        }

    }

}


.mnro-section-right-picture {


    overflow-x: hidden;

    @media(max-width: $mnro-tablet-break ) {
        text-align: center;
    }

    > section {
        width: 850px;
        margin: 0 auto;
        min-height: 473px;
        position: relative;

        @media(max-width: $mnro-pc-break ) {
            padding: 0 60px;
            width: auto;
        }

        @media(max-width: $mnro-tablet-break ) {
            min-height: auto;
        }
    }

    .devices {
        position: absolute;
        left: 430px;
        top: -15px;

        @media(max-width: $mnro-tablet-break ) {
            display: none;
        }
    }

    h4 {
        font-size: 24px;
        color: $header-text-color;
        font-weight: 400;
        margin-bottom: 25px;
    }

    p {
        width: 300px;
        margin-bottom: 25px;
        color: $normal-text-color;
        line-height: 24px;

        @media(max-width: $mnro-tablet-break ) {
            width: auto;
        }
    }

    .icons {
        i {
            color: #C2C0E7;
            margin-right: 20px;
        }

        .ion-monitor {
            font-size: 44px;
        }

        .ion-ipad {
            font-size: 38px;
        }

        .ion-iphone {
            font-size: 32px;
        }
    }
}



.mnro-section-right-picture2 {

    overflow-x: hidden;

    @media(max-width: $mnro-tablet-break ) {

        text-align: center;
    }

    section {
        width: 850px;
        margin: 0 auto;
        min-height: 473px;
        position: relative;

        @media(max-width: $mnro-pc-break ) {
            padding: 0 60px;
            width: auto;
        }

        @media(max-width: $mnro-tablet-break ) {
            min-height: auto;
        }
    }

    img.browser {
        position: absolute;
        left: 450px;
        top: -15px;

        @media(max-width: $mnro-tablet-break) {
            display: none;
        }
    }

    .info {
        width: 330px;

        @media(max-width: $mnro-tablet-break) {
            width: auto;
        }
    }

    h3 {
        font-size: 14px;
        color: #70A9CD;
        text-transform: uppercase;
        line-height: 17px;
        letter-spacing: 2px;
        font-weight: 600;
    }

    h4 {
        font-size: 27px;
        color: #4B4B4B;
        line-height: 36px;
        letter-spacing: 0px;
        font-weight: 300;
        margin-top: 15px;
    }

    p {
        font-size: 15px;
        color: #7B8398;
        line-height: 27px;
        margin-top: 30px;
    }
}



.mnro-quotes {
    text-align: center;



    h2 {
        text-transform: uppercase;
        font-size: 13px;
        color: #4E7198;
        letter-spacing: 1px;
    }

    .quotes {
        margin-top: 40px;

        @media(max-width: $mnro-tablet-break) {
            height: 260px;
        }

        .mnro-quote {
            opacity: 0;
            //position: absolute;
            //left: 0;
            //right: 0;


            @include transition(opacity .4s);

            &.active {
                opacity: 1;
                z-index: 10;

                @include animation(slide-quote .8s cubic-bezier(.2,.6,.3,1));
            }

            .quote {
                font-size: 21px;
                width: 620px;
                margin: 0 auto;
                line-height: 37px;
                color: #4a5879;

                @media(max-width: $mnro-tablet-break) {
                    width: auto;
                    padding: 0 30px;
                    font-size: 17px;
                    line-height: 34px;
                }
            }

            .author {
                margin-top: 35px;
                font-size: 17px;

                .name {
                    color: #4a5879;
                }

                .role {
                    color: #9CAFDA;
                    margin-left: 6px;
                }
            }
        }
    }


}



.mnro-two-columns-content {


    .container2 {
        clear: left;
        float: left;
        width: 100%;
        overflow: hidden;
        border-top: 1px solid #e2e9f0;

        .container1 {
            float: left;
            width: 100%;
            position: relative;
            right: 50%;
            background: #f5f7f9;
            border-right: 1px solid #e2e9f0;

            @media(max-width: $mnro-pc-break) {
                right: auto;
            }
        }
    }

    .col-left {
        float: left;
        width: 50%;
        position: relative;
        left: 50%;
        overflow: hidden;
        padding: 78px 50px 0;

        @media(max-width: $mnro-pc-break) {
            width: 100%;
            left: 0;
            padding: 60px 70px 0;
            display: none;
        }

        @media(max-width: $mnro-tablet-break) {
            padding: 50px 30px 0;
        }

        .features {
            float: right;
            width: 400px;

            @media(max-width: $mnro-pc-break) {
                float: none;
                width: auto;
            }

            .feature {
                margin-bottom: 30px;
            }

            .image, .info {
                float: left;

                @media(max-width: $mnro-tablet-break) {
                    float: none;
                }
            }

            .info {
                width: 80%;
                margin-left: 40px;

                @media(max-width: $mnro-tablet-break) {
                    width: auto;
                    margin-left: 0;
                    margin-top: 20px;
                }

                strong {
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #444;
                    letter-spacing: .5px;
                }

                p {
                    color: #657790;
                    margin-top: 12px;
                    line-height: 24px;
                    width: 90%;
                }
            }
        }
    }

    .col-right {
        float: left;
        width: 50%;
        position: relative;
        left: 50%;
        overflow: hidden;
        padding: 50px 70px 70px;

        @media(max-width: $mnro-pc-break) {
            width: 100%;
            left: 0;
        }

        @media(max-width: $mnro-tablet-break) {
            padding: 30px;
        }

        .info {
            width: 340px;

            @media(max-width: $mnro-pc-break) {
                width: auto;
            }
        }

        h3 {
            font-size: 13px;
            margin-top: 20px;
            color: #70A9CD;
            text-transform: uppercase;
            line-height: 17px;
            letter-spacing: 2px;
            font-weight: 600;
        }

        h4 {
            font-size: 27px;
            color: #4B4B4B;
            line-height: 37px;
            font-weight: 300;
            margin-top: 15px;
        }

        p {
            font-size: 15px;
            color: #7B8398;
            line-height: 27px;
            margin-top: 30px;
        }
    }
}


.mnro-staff-pictures {
    margin-top: 15px;

    .mnro-staff-picture {
        display: inline-block;

        &:hover,
            &.active {
            img {
                box-shadow: 0 20px 35px rgba(0,0,0,.3);

                @include transform(scale3d(.8, .8 ,.8) translateY(-5px));

                @media(max-width: $mnro-tablet-break) {
                    @include transform(scale3d(.7, .7 ,.7) translateY(-5px));
                }
            }
        }

        img {
            border-radius: 100px;
            //cursor: pointer;

            @include transform(scale3d(.65, .65 ,.65));
            @include transition(all .2s ease-out);

            @media(max-width: $mnro-pc-break) {
                max-width: 100px;
            }
        }
    }
}


.mnro-about-intro {

    margin-top: 90px;

    p {
        text-align: center;
        font-size: 17px;
        width: 65%;
        margin: auto;
        line-height: 32px;
        color: #828A98;

        @media(max-width: $mnro-tablet-break) {
            width: auto;
        }
    }
}



.mnro-article {

    margin-top: 70px;

    @media(max-width: $mnro-tablet-break) {
        margin-top: 40px;
    }

    > .container {
        @media (min-width: 1200px) {
            width: 880px;
        }
    }

    .mnro-article-title {

        font-size: 31px;
        font-weight: 400;
        color: #444;

        @media(max-width: $mnro-pc-break) {
            font-size: 28px;
        }

        @media(max-width: $mnro-tablet-break) {
            font-size: 24px;
            line-height: 35px;
        }
    }


    .mnro-article-content {
        margin-top: 50px;

        @media(max-width: $mnro-tablet-break) {
            margin-top: 30px;
        }

        h1, h2, h3, h4, h5 {
            margin: 45px 0 20px;
            font-size: 23px;
        }

        p {
            font-size: 16px;
            color: #6b6b6b;
            line-height: 29px;
            margin-bottom: 20px;

            @media(max-width: $mnro-pc-break) {
                font-size: 14px;
            }
        }





    }

}


.mnro-credits {


    list-style: none;

    li {


        opacity: 0.6;

        padding: 0.5em 0.8em;

        margin-left: 1em;

        font-size: 0.8em;

        display: inline-block;

        cursor: pointer;

        border-radius: 0.5em;

        background-color: #000;

        &:hover {

            opacity: 1;
        }

        a {



            color: #FFFFFF;

        }

    }

}


/* *** MNRO FOOTER *** */
.lt-ie10 .mnro-footer {

    display: none;

}


.mnro-footer {

    //padding: 40px 0;
    text-align: center;
    height: $footer-height;
    background: #212A38;
    font-size: 16px;


    span {

        margin: 0 55px;
        line-height: $footer-height;
        color: #AEBDD3;

    }

    a {
        color: #AEBDD3;
        text-decoration: none;
        margin: 0 15px;

        @media(max-width: $mnro-tablet-break) {
            display: none;
        }
    }

    //margin-top: 120px;

}





/* *** MNRO NAVBAR *** */
.mnro-top-navbar {

    @extend .navbar;
    @extend .bg-transparent;
    @extend .navbar-dark;


    .mnro-hidden-on-collapse {

        @media(min-width: $mnro-pc-break) {

            display: none;
        }

    }

}





/* *** MNRO SUB NAVBAR *** */
.mnro-features-nav {
    //height: 85px;

    .features {
        text-align: center;
        border-bottom: 1px solid #ddd;

        &.fixed {
            position: fixed;
            top: 0;
            width: 100%;
            background: #fff;
            z-index: 9999;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            @include animationDuration(.5s);
        }

        li {
            display: inline-block;

            &:nth-child(1) a {
                @include animationDelay(.35s);
            }

            &:nth-child(2) a {
                @include animationDelay(.5s);
            }

            &:nth-child(3) a {
                @include animationDelay(.65s);
            }

            &:nth-child(4) a {
                @include animationDelay(.8s);
            }

            &:nth-child(5) a {
                @include animationDelay(1s);
            }
        }

        .nav-link {
            display: inline-block;
            padding: 20px 0;
            height: auto;
            vertical-align: top;
            margin: 0 10px;
            font-size: 12px;
            width: 170px;
            color: #333;
            position: relative;
            overflow: hidden;

            @media(max-width: $mnro-pc-break) {
                width: 150px;
                font-size: 14px;
            }

            &.active {
                color: #ea9538;

                &:after {
                    bottom: 0;
                    background: #f7b063;
                }
            }

            &:after {
                content: '';
                position: absolute;
                height: 3px;
                bottom: -3px;
                left: 0;
                width: 100%;

                @include transition(all .15s linear);
            }

            &:hover,
                &:focus {
                opacity: 1;
                background: none;

                &:after {
                    bottom: 0;
                    background: #fdd0ad;
                }
            }
        }
    }
}


/* *** BRAND BOX *** */
.brand-box {

    width: auto;
    height: 40px;
    display: inline-block;



    .logo-text {

        width: auto;
        vertical-align: middle;
        margin-left: 4px;
        line-height: 40px;
        letter-spacing: 3px;
        font-size: 14px;
        display: inline-block;
        height: auto;
        color: #fff;




    }

    .animated-icon-box {


        width: 24px;
        vertical-align: middle;
        position: relative;
        height: 24px;
        display: inline-block;


        .outer-orbit {

            width: 20px;
            top: 2px;
            position: absolute;
            left: 2px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid #e6e6e6;
            -webkit-animation: spin 7s linear infinite;
            animation: spin 8s linear infinite;

            .outer-orbit-circle {

                width: 4px;
                top: -2px;
                position: absolute;
                left: 10px;
                height: 4px;
                border-radius: 100%;
                background-color:  #FFFFFF;

            }
        }

        .inner-orbit {

            width: 10px;
            top: 7px;
            position: absolute;
            left: 7px;
            height: 10px;
            border-radius: 100%;
            border: 1px solid #e6e6e6;
            -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;

            .inner-orbit-circle {

                width: 3px;
                top: -2px;
                position: absolute;
                left: 3px;
                height: 4px;
                border-radius: 100%;
                background-color:  #FFFFFF;

            }
        }

    }

}

.mini-brand-box {

    @extend .brand-box;

    height: auto;


}

/* *** bars *** */
.bars {

    .bar {

        text-align: center;
        padding: 20px 0;

        h3 {

            font-size: 1.4em;
            font-weight: normal;
            margin: 0;
            text-transform: uppercase;
            color: #285571;

        }

        .bar-circle {

            display: block;
            margin: 0.7em auto;

        }
    }

}



/* *** ANIMATIONS *** */
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); } 
}


@keyframes blink { 

    0% { 
        opacity: 0.2; 
    }

    50% { 
        opacity: 0.8; 
    }

    100% { 
        opacity: 0.2; 
    }

}



/* *** MNRO INDEX PAGE *** */
.mnro-index-header {
    

    background: url('#{$imagesPath}/unsplash/madrid-002-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    @media( min-width: $mnro-mobile-break ) {
        
         background: url('#{$imagesPath}/unsplash/madrid-002-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background: url('#{$imagesPath}/unsplash/madrid-002-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/madrid-002-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/madrid-002-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }
    

    

    //background: url('#{$imagesPath}/unsplash/madrid-skyline-001-trama-blue.jpg') #29394E no-repeat;

    //background: url('#{$imagesPath}/unsplash/madrid-cartoon-001.jpg') #29394E no-repeat;

    //madrid-skyline-001-trama-blue.jpg



}


.mnro-index-main-features {

    @extend .mnro-main-features;
    @extend .mnro-main-block;
}


.mnro-index-sub-features {

    @extend .mnro-sub-features;
    @extend .mnro-top-separed;
    @extend .mnro-secondary-block;

    .features {

        width: 95%;

        @media(max-width: $mnro-tablet-break ) {
            width: 88%;
        }

    }


}


.mnro-index-como-trabajamos {

    @extend .mnro-section-right-picture;
    @extend .mnro-top-separed;
    @extend .mnro-secondary-block;

}

.mnro-index-quotes {

    @extend .mnro-quotes;
    @extend .mnro-top-separed;
    @extend .mnro-main-block;

}



/* *** MNRO SERVICIOS IT *** */
.mnro-servicios-it-header {

    background: url('#{$imagesPath}/unsplash/everest-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
    
    
    background: url('#{$imagesPath}/unsplash/everest-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    @media( min-width: $mnro-mobile-break ) {
        
         background: url('#{$imagesPath}/unsplash/everest-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background: url('#{$imagesPath}/unsplash/everest-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/everest-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/everest-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }
    

}


.mnro-servicios-it-features-nav {

    @extend .mnro-features-nav;

    .features {
        .nav-link {


            @media(max-width: $mnro-pc-break) {
                width: 120px;
                font-size: 10px;
            }


        }
    }

}


.mnro-servicios-it-infraestructura-red, .mnro-servicios-it-monitorizacion {

    @extend .mnro-section-right-picture2;
    @extend .mnro-main-block;
}

.mnro-servicios-it-monitorizacion {

    @extend .mnro-top-separed;

}


.mnro-servicios-it-infraestructura-logica {

    @extend .mnro-sub-features;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;

    @extend .mnro-sub-features-1-col;

    header {

        text-align: center;

        h3 {
            font-size: 14px;
            color: #70A9CD;
            text-transform: uppercase;
            line-height: 17px;
            letter-spacing: 2px;
            font-weight: 600;
        }

        h4 {
            font-size: 27px;
            color: #4B4B4B;
            line-height: 36px;
            letter-spacing: 0px;
            font-weight: 300;
            margin-top: 15px;
        }

    }


}


.mnro-servicios-it-copias-seguridad {

    @extend .mnro-two-columns-content;

    border-bottom: 1px solid #e2e1e1;
}



.mnro-servicios-it-quotes {

    @extend .mnro-quotes;
    @extend .mnro-main-block;


}



/* *** MNRO DESARROLLO SW *** */
.mnro-desarrollo-sw-header {

    background: url('#{$imagesPath}/unsplash/cactus-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    
    @media( min-width: $mnro-mobile-break ) {
        
         background:url('#{$imagesPath}/unsplash/forest-001-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background:url('#{$imagesPath}/unsplash/forest-001-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/forest-001-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/forest-001-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }

}


.mnro-desarrollo-sw-features-nav {

    @extend .mnro-features-nav;

    .features {
        .nav-link {


            @media(max-width: $mnro-pc-break) {
                width: 120px;
                font-size: 10px;
            }


        }
    }

}



.mnro-desarrollo-sw-web, 
.mnro-desarrollo-sw-app-moviles, 
.mnro-desarrollo-sw-skills {

    @extend .mnro-sub-features;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;


    @extend .mnro-sub-features-1-col;

    header {

        text-align: center;

        h3 {
            font-size: 14px;
            color: #70A9CD;
            text-transform: uppercase;
            line-height: 17px;
            letter-spacing: 2px;
            font-weight: 600;
        }

        h4 {
            font-size: 27px;
            color: #4B4B4B;
            line-height: 36px;
            letter-spacing: 0px;
            font-weight: 300;
            margin-top: 15px;
        }

    }

}



.mnro-desarrollo-sw-cms {

    @extend .mnro-two-columns-content;

}


.mnro-desarrollo-sw-skills {

    .card {
        margin-bottom: 60px;

    }

}


.mnro-desarrollo-sw-quotes {

    @extend .mnro-quotes;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;

}








/* *** MNRO CMS *** */
.mnro-cms-header {

    background: url('#{$imagesPath}/unsplash/mars-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    
    @media( min-width: $mnro-mobile-break ) {
        
         background:url('#{$imagesPath}/unsplash/mars-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background:url('#{$imagesPath}/unsplash/mars-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/mars-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/mars-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }

}


.mnro-cms-features-nav {

    @extend .mnro-features-nav;

    .features {
        .nav-link {


            @media(max-width: $mnro-pc-break) {
                width: 120px;
                font-size: 10px;
            }


        }
    }

}



.mnro-cms-about-intro {

    @extend .mnro-about-intro;

}


.mnro-gestores-de-contenido {

    @extend .mnro-two-columns-content;
    
    margin-top: 60px;
    
    .feature {
        
        .image {
            
            img {
                
                width: 36px;
            }
        }
    }

}


.mnro-lista-completa-cms {

    .card {
        
        margin-bottom: 80px;
        
        .mnro-supported-cms {
         
            padding: 40px;
            
        }

    }

}


.mnro-cms-populares, 
.mnro-lista-completa-cms {

    @extend .mnro-sub-features;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;


    @extend .mnro-sub-features-1-col;
    
    .features {

        section {

            @media(min-width: $mnro-pc-break ) {

                width: 80%;
            }


        }

    }

    header {

        text-align: center;

        h3 {
            font-size: 14px;
            color: #70A9CD;
            text-transform: uppercase;
            line-height: 17px;
            letter-spacing: 2px;
            font-weight: 600;
        }

        h4 {
            font-size: 27px;
            color: #4B4B4B;
            line-height: 36px;
            letter-spacing: 0px;
            font-weight: 300;
            margin-top: 15px;
        }

    }

}









.mnro-desarrollo-sw-quotes {

    @extend .mnro-quotes;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;

}





/* *** MNRO PROTOTIPADO *** */
.mnro-prototipado-header {

    background: url('#{$imagesPath}/unsplash/cactus-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    
    @media( min-width: $mnro-mobile-break ) {
        
         background:url('#{$imagesPath}/unsplash/cactus-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background:url('#{$imagesPath}/unsplash/cactus-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/cactus-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background:url('#{$imagesPath}/unsplash/cactus-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }

    //background: url('#{$imagesPath}/unsplash/piramide-cartoon.jpg') $mnro-header-bg-color no-repeat;
}


.mnro-prototipado-about-intro {

    @extend .mnro-about-intro;


}


.mnro-prototipado-diseno {

    @extend .mnro-sub-features;
    @extend .mnro-main-block;
    @extend .mnro-top-separed;

    @extend .mnro-sub-features-1-col;

    header {

        text-align: center;

        h3 {
            font-size: 14px;
            color: #70A9CD;
            text-transform: uppercase;
            line-height: 17px;
            letter-spacing: 2px;
            font-weight: 600;
        }

        h4 {
            font-size: 27px;
            color: #4B4B4B;
            line-height: 36px;
            letter-spacing: 0px;
            font-weight: 300;
            margin-top: 15px;
        }

    }


}


.mnro-prototipado-diseno-sw {

    @extend .mnro-two-columns-content;

    border-bottom: 1px solid #e2e1e1;
}




.mnro-servicios-prototipado-diseno-dispositivos {

    @extend .mnro-section-right-picture2;
    @extend .mnro-main-block;


}



.mnro-prototipado-diseno-quotes {

    @extend .mnro-quotes;
    @extend .mnro-main-block;

    border-top: 1px solid #e2e1e1;

}



/* *** CONTACTO *** */
.mnro-contact-header {

    background: url('#{$imagesPath}/unsplash/sea-001-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    
    @media( min-width: $mnro-mobile-break ) {
        
         background: url('#{$imagesPath}/unsplash/sea-001-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background: url('#{$imagesPath}/unsplash/sea-001-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/sea-001-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/sea-001-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }
}


.mnro-alt-contact-info {
    padding: 80px 0 90px;

    .col-md-6 {

        @media(max-width: $mnro-tablet-break) {
            text-align: center;
            margin-bottom: 25px;
        }

        @media(min-width: $mnro-pc-break) {
            padding-left: 25px;
            border-left: 1px solid #ddd;
            padding-top: 10px;
        }
    }

    h4 {
        font-weight: 400;

        @media(max-width: $mnro-tablet-break) {
            font-size: 1.3rem;
        }

        i {
            font-size: 2rem;
            position: relative;
            top: 4px;
            margin-right: 8px;
        }
    }

    p {
        font-size: 17px;
    }
}




.mnro-contact-form {

    @extend .mnro-form;
}



.mnro-contact-form-intro {

    @extend .mnro-form-intro;
}


/* *** LEGAL *** */
.mnro-legal-header {

    background: url('#{$imagesPath}/unsplash/piramide-cartoon-480.jpg') $mnro-header-bg-color no-repeat;
    
    
    @media( min-width: $mnro-mobile-break ) {
        
         background: url('#{$imagesPath}/unsplash/piramide-cartoon-768.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-tablet-break ) {
        
         background: url('#{$imagesPath}/unsplash/piramide-cartoon-992.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/piramide-cartoon-1800.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
    @media( min-width: $mnro-large-pc-break ) {
        
         background: url('#{$imagesPath}/unsplash/piramide-cartoon-original.jpg') $mnro-header-bg-color no-repeat;
        
    }
    
}


/* *** MODALS *** */
.mnro-msg-modal {

    width: auto;

    top: 36%;

    text-align: center;


    .modal-dialog {

        width: auto;

        display: inline-block;

        .modal-body {

            text-align: center;

            color: $mnro-text-over-main-dark-color;

            background-color: $mnro-main-dark-color;


            h6 {
                margin: 0;

            }


            .mini-brand-box {

                width: auto;

                margin-bottom: 1em;

                height: auto;

            }

        }

    }

}





// IE 10?
.navbar-expand-lg .navbar-collapse {

    @media( min-width: $mnro-pc-break ) {

        flex: 1;

    }


}


